




























import { Action } from "vuex-class";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {
    Avatar: () => import("@/components/general/avatar.vue"),
  },
})
export default class Comments extends Vue {
  @Action("comment/create") createComment!: (payload: CommentPayload) => Promise<Comment>;

  @Action("comment/index") indexComments!: (payload: SearchIndexPayload) => Promise<Comment[]>;

  @Prop() type!: "dots" | "hunches";

  @Prop() typeId!: number;

  comments: Comment[] = [];

  loadingComments = false;

  payload: CommentPayload = {
    body: "",
    commentable_type: this.type,
    commentable_id: this.typeId,
  };

  mounted() {
    this.handleIndexComments();
  }

  private handleIndexComments() {
    const payload: SearchIndexPayload = {
      q: {
        and: {
          commentable_type: this.type,
          commentable_id: this.typeId,
        },
      },
      s: { created_at: "desc", id: "desc" },
    };
    this.loadingComments = true;
    this.indexComments(payload)
      .then((comments: Comment[]) => {
        this.comments = [...comments];
      })
      .finally(() => {
        this.loadingComments = false;
      });
  }

  private handleCommentCreate(e: KeyboardEvent) {
    if (e.ctrlKey) {
      return;
    }

    if (this.payload.body.replace(" ", "") === "") {
      return;
    }

    const oldValue: string = this.payload.body;
    this.payload.body = "";

    this.createComment({ ...this.payload, body: oldValue })
      .then((comment: Comment) => {
        this.comments.unshift(comment);
      })
      .catch((e: any) => {
        this.payload.body = oldValue;
      });
  }
}
